import React from "react";
import { AiOutlineCloseCircle } from 'react-icons/ai';

export const Modal = ({isOpen, onClose, backdropClose, cssClass, children}) =>{
    return(
        <div hidden={!isOpen} onClick={backdropClose && onClose} className={`modal-backdrop ${cssClass}`}>
            <div onClick={e=>e.stopPropagation()} className="modal">
                <AiOutlineCloseCircle onClick={onClose} className="modal-close" />
                {children}
            </div>
        </div>
    )
}