import knowledge from "../../images/knowledge.png";
import drones from "../../images/drones.png";
import books from "../../images/books.png";
import code from "../../images/code.png";
import brazenLogo from "../../images/brazenLogo.png";

export const home_service_list = [
    {
        info: "Ex veniam elit consectetur excepteur labore reprehenderit mollit ad incididunt eu et nostrud et. Duis elit laboris eiusmod duis excepteur exercitation aliqua. Consectetur magna culpa est sit. Ad in ut cillum proident ad reprehenderit consectetur proident non ullamco officia do duis nisi. Deserunt ad aute minim quis esse eiusmod consequat.",
        img: knowledge
    },
    {
        info: "Ex veniam elit consectetur excepteur labore reprehenderit mollit ad incididunt eu et nostrud et. Duis elit laboris eiusmod duis excepteur exercitation aliqua. Consectetur magna culpa est sit. Ad in ut cillum proident ad reprehenderit consectetur proident non ullamco officia do duis nisi. Deserunt ad aute minim quis esse eiusmod consequat.",
        img: drones
    },
    {
        info: "Ex veniam elit consectetur excepteur labore reprehenderit mollit ad incididunt eu et nostrud et. Duis elit laboris eiusmod duis excepteur exercitation aliqua. Consectetur magna culpa est sit. Ad in ut cillum proident ad reprehenderit consectetur proident non ullamco officia do duis nisi. Deserunt ad aute minim quis esse eiusmod consequat.",
        img: code
    },
    {
        info: "adfha sdfjasd fkjalsdkla lfja lsdkfjal jsdklafj klsdjf alsdkjf alsdjfl ajsdflajslfjalsdjflajs lfajsdlfjasldfj alsdj als djaflsdjlfajsdlfajsdlj      Ex veniam elit consectetur excepteur labore reprehenderit mollit ad incididunt eu et nostrud et. Duis elit laboris eiusmod duis excepteur exercitation aliqua. Consectetur magna culpa est sit. Ad in ut cillum proident ad reprehenderit consectetur proident non ullamco officia do duis nisi. Deserunt ad aute minim quis esse eiusmod consequat.",
        img: books
    },
]

export const home_partners = [
    {
        info: "Brazen Group has a highly skilled team and a unique approach to Salesforce implementation that ensures that every client has a successful outcome. On top of that, we have beliefs and convictions, like integrity and hard work, that guide our actions to the benefit of our clients and partners.",
        img: brazenLogo,
        link: "https://www.brazengroup.com"
    }
]

export const home_info = {
    aboutUs: `The Caribbean Coding Academy (CCA) is a registered business on the island of Grenada that has
        a Technology Business Incubator (TBI) project which intends to address the issue of
        unemployment among young talented youths and graduates in Grenada and by extension the
        Caribbean.`,
    economicBenefit: `Caribbean Coding Academy's primary objective is to bring opportunity to young Grenadians to
        increase the potential of businesses, to provide solutions to Government's social and economic
        problems through high paying jobs. This academy will help to grow our economy while enticing
        and retaining young professionals at home. It will have a similar or even higher impact than our
        current revenue generating sectors like tourism, thereby contributing to higher economic growth
        in the foreseeable future.
        
        The potential growth and innovation of software coding and the benefits it delivers will depend
        on policies and support given to encourage innovation and investment. A Coding Academy can
        provide policy makers and businesses with smarter opportunities to grow our economy, provide a
        
        brighter future to our youths, boost education and raise standard of living. There will be many
        indirect and direct benefits of the CCA and TBI program to Grenada and the region:`,
    publicPrivateBusinesses: `Our research has found that the software industry is having a potential impact that is rippling
        throughout the economy, creating high skill jobs, boosting wages, improving productivity,
        increasing exports and expanding the country's economic potential. The potential economic
        activities enable an increase in demand for goods and services due to higher wages paid to those
        in the software industry and those in the industries that supply directly to the software companies.`,
    riskAnalysis: `The key to surviving any risks is knowing how to assess and respond to them. Devoting the
        resources to establish Caribbean Coding Academy is well worth it. We are committed to
        meticulously managing even relatively small risks that we may encounter in this program, for
        instance our human resource or coders will be our biggest risk. We are prepared to achieve
        greater value by taking a disciplined and systematic approach to mitigating the strategic risks that
        can make or break us. We will have a stringent interview process that will give us the best
        candidates for the program. There will always be unpreventable events that may damage our
        academy—which makes it more important to adequately manage preventable risks. Presently, we
        are spending millions of dollars in technical and educational training and more in creating
        permanent and seasonal employment for our youths. If we don't invest in this sector, our young
        people will not be prepared to take advantage of future jobs. We will risk the opportunity to
        retain our brightest talents and our human resources in the region will be at a disadvantage to
        compete in the global economy.`,
    conclusion: `The success of the CCA will have a far-reaching impact on the lives of our graduates, local
        businesses, and financial institutions, as well as to the government with an increase in revenue in
        the form of taxes and other indirect revenues and benefits. The growth of human resources, in
        this field, will encourage software and hardware innovation, entrepreneurship and job creation,
        which will be pioneered by our TBI initiative. Most importantly, the macro and micro
        contributions to employment and foreign exchange will have serious long-term benefits for
        Grenada’s human resource development and economic growth. Software is at the forefront of
        Global innovation — laying the groundwork in Grenada and by extension in the Caribbean and
        will advance our mission to bring hope to every young man or woman for a bright future. The
        method of delivering the courses will be like an online university. The entire curriculum will be a
        cloud base and pier to pier training and students will be required to attend classes at a specific
        location, where proctors will be available to answer questions. It is a Significant, Measurable,
        Attainable, Relevant, Timely investment, where software innovation can advance a well-trained
        workforce, provide a handsome return on investment adding to governments revenues while
        harnessing innovation.`,
    economicBenefitList: [
        `A coder can work in a wide range of industries locally and regionally, but generally in offices
        as salaried employees, as a computer software engineer and designer to maintain source code
        related to various computer programs.
        They may also be used to customize “off the shelf” computer programs which were
        purchased from foreign vendors.`,
        `Acceptance of software application projects from local companies that support and provide
        tangible assistance to the development of the academy, working closely with local
        businesses as solution providers to deliver on their software application needs.`,
        `Businesses that support the academy will be asked to submit projects or software application
        problems for trainees to develop.`,
    ]
}