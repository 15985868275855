import React from "react";
import { teamContent, teamMembers } from "../contents/teams";
import { TeamCardList } from "../../components/TeamCardList";
import { PublicLayout } from "../layouts/PublicLayout";


export const Teams = () =>{
    return(
        <PublicLayout>
            <div className="team-container">
                <div className="team-content">
                    <h1>Who are we?</h1>
                    <div className="team-seperator"/>
                    <p>{teamContent.aim}</p>
                    <p>{teamContent.objective}</p>
                </div>
                <TeamCardList 
                    title="Meet the Team" 
                    teams={teamMembers} 
                />
            </div>
        </PublicLayout>
    )
}