import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import bg_videos from '../../videos/moodle-video.mp4';
import { navigationList } from '../contents/header';
import { FaHatCowboy } from 'react-icons/fa';
import { routes } from '../../routes/Routes';
import { AiOutlineMenu } from 'react-icons/ai';
import $ from 'jquery';



export const Moodle = () =>{
    const navigate = useNavigate();

    const navRef = useRef();

    const showNav = () =>{
        if ($(navRef.current).attr('class')?.includes('hide-on-mobile')){
            $(navRef.current).removeClass('hide-on-mobile');
        }else{
            $(navRef.current).addClass('hide-on-mobile');
        }
    }

    const goToMoodle = () =>{
        window.open('https://caribbeancodingacademygrenada.com/moodle/', '_blank');
    }

    return(
        <div>
            <video className='moodle-video-background' loop autoPlay muted >
                <source src={bg_videos} />
            </video>
            <div className="moodle-container">
                <div className="moodle-header">
                    <span><FaHatCowboy className="moodle-header-icon" />Moodle</span>
                    <AiOutlineMenu onClick={showNav} className="moodle-menu" />
                    <div ref={navRef} className="hide-on-mobile">
                        {navigationList.map((nav, key)=>(
                            <span 
                                onClick={()=>nav.href === routes.moodle()? goToMoodle() : navigate(nav.href)} 
                                style={{color: nav.href === routes.moodle() && 'var(--secondary-color'}}
                                key={key}
                            >{nav.title}</span>
                        ))}
                    </div>
                </div>
                <div className="moodle-middle-content">
                    <div className="moodle-sub-header">Motivationally driven, and supported by many oganization.</div>
                    <div>welcome to the moodle learning platform where you'll discover the value of an open, collaborative effor by one of the largest team in grenada.</div>
                    <button onClick={goToMoodle}>GO TO MOODLE</button>
                </div>
                <div className="moodle-bottom-info">
                    <div>Kids learn to code</div>
                    <div>Adult Learn to Code</div>
                    <div>Bootcamp</div>
                    <div>Business Incubator</div>
                </div>
            </div>
        </div>
    )
}