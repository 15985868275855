import { MdPayment } from 'react-icons/md';
import { FaLaptopCode, FaFileCode, FaChalkboardTeacher } from 'react-icons/fa';
import { GiFarmer } from 'react-icons/gi';


export const projectsObjective = `
    Here are a few of those projects we're currently working on, more to follow soon
`

export const projects = [{
        title: "Bill Payment App",
        subject: `
        Online BillPay is a free and secure web base online bill payment platform service that allows customers to pay their monthly bills​
        `,
        icon: MdPayment
    },
    {
        title: "Burial App",
        subject: `
        An Application to help you connect with a funeral home and gives you ease of access service in your time of need
        `,
        icon: FaLaptopCode
    }, {
        title: "Farmers' App",
        subject: `
        At the fingertips of the farmers is the ability to connect with customer by posting of new produce for sale. They also have an opportunity for the farmer to connect directly with their Patrons
        `,
        icon: GiFarmer
    }, {
        title: "Coming Soon",
        subject: `
        More information coming soon.
        `,
        icon: FaFileCode
    }, {
        title: "Coming Soon",
        subject: `
        More information coming soon.
        `,
        icon: FaChalkboardTeacher
    }
]