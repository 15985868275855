import React from "react";

export const FilterOption = () =>{
    return(
        <div className="filter-option border-bottom opacity-white-25">
            <div className="d-flex align-items-center">
                <div className="ms-3">Filter:</div>
                <div className="filter border">
                    <select className="pointer">
                        <option>Hifher</option>
                        <option>Hifher</option>
                    </select>
                    <input placeholder="Search..."/>
                </div>
            </div>
        </div>
    )
}