import { token } from "../tools/Token";

export class Login{
    constructor(API){
        this.api = API;
    }

    async session(){
        return await this.api.get('/fetch/session', {token: token.get()});
    }

    async login(data){
        return await this.api.get('/signin', data);
    }
}