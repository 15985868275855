import React, { useEffect } from "react";
import { tools } from "../../tools/Tools";
import { services, servicesObjective } from "../contents/Services";
import { PublicLayout } from "../layouts/PublicLayout";


export const Services = () =>{
    const buildId = (index) =>{
        tools.inView("#serviceCard" + index, "cart-left-to-right-animation");
        return "serviceCard" + index;
    }

    useEffect(()=>{
        
    }, []);
    return(
        <PublicLayout>
            <div className="service-container">
                <div className="service-content">
                    <h1>Our Services</h1>
                    <p>{servicesObjective}</p>
                </div>
                <div className="service-card-container">
                    {services.map((service, key)=>(
                        <div id={buildId(key)} className="service-card cart-left-to-right-animation cart-left-to-right-animation-starter" key={key}>
                            <div>
                                {service?.icon && <service.icon className="services-icon" />}
                            </div>
                            <b>{service.title}</b>
                            <p>{service.subject}</p>
                        </div>
                    ))}
                </div>
            </div>
        </PublicLayout>
    )
}