import axios from "axios";
import { Login } from "./Login";
import { User } from "./User";

export class Api{
    //'https://www.caribbeancodingacademygrenada.com/ccawebsiteservice'
    baseURL = '/ccawebsiteservice';
    pendingRequests = [];

    constructor(){
        this.axios = axios.create({baseURL: this.baseURL});
        this.user = new User(this);
        this.login = new Login(this);
    }

    async post(route, data){
        return await this.axios.post(route, data);
    }

    async get(route, data){
        //return await this.axios.get(route, {params: {}}, data);
        return await this.axios.post(route, data);
    }
}

export const api = new Api();
