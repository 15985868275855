import React, { useRef } from "react";
import logo from '../../images/CCA-Logo.png';
import { useNavigate } from "react-router-dom";
import { PrivateLayout } from "../layouts/PrivateLayout";

export const AdminHome = () =>{
    return(
        <PrivateLayout>
            <div className="display-3 position-absolute top-50 start-50 translate-middle text-center text-primary opacity-white-50 p-3">
                <div className="display-5 fs-bold">Welcome</div>
                <div className="display-5 fs-bold">to</div>
                <div className="display-5 fs-bold">Caribean Coding Accademy</div>
                <div className="display-5 fs-bold">Administration</div>
            </div>
        </PrivateLayout>
    )
}