import React from "react";

export const CCALocation = () =>{
  return (
    <div style={{height: '100%', width: '100%'}}>
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15607.604136550533!2d-61.7488067!3d12.0503299!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c3821da25fb3fd3%3A0xb9ee9dc8daca3009!2sCaribbean%20Coding%20Academy!5e0!3m2!1sen!2s!4v1699161354269!5m2!1sen!2s" 
        width="100%" 
        height="100%" 
        style={{border: '0'}} 
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
