import samira from '../../images/samira.jpg';
import mallon from '../../images/mallon.jpg';
import richard from '../../images/richard.jpg';
import priest from '../../images/priest.jpg';
import oketo from '../../images/oketo.jpg';
import rockim from '../../images/rockim.jpg';

export const developersContent = {
    aim: ``,
    objective: ``,
}

export const developersMembers = [{
        name: "Samira McQueen",
        role: "Full Stack Developer",
        image: samira,
        bio: "Junior Software Developer seeking to increase my skills and experience. I have experience with the various languages: Java, SQL, XML, HTML, C++, C#, JavaScript, and different JavaScript frameworks, Game Design and Development as I completed courses while attending the St. George’s University and online courses. I also have extensive knowledge in System Development, Information System Security, Data Communication & Networking, Information System Analysis, Hardware and Operating Systems, complemented by business courses that I have completed, including Management and Marketing.​",
        git: "https://github.com/smcqueen-95",
        linkedIn: "https://www.linkedin.com/in/samira-mc-queen-1882431a7/",
    }, {
        name: "Mallon Blair",
        role: "Web Developer",
        image: mallon,
        bio: "I have just over a year of experience I'm a fast learner. As a junior software developer with about a year or experience in my short time there, I've already contributed to over a dozen projects and assisted with managing one project for one of the firm's long-time clients. I'm familiar with React JS, Firebase, python, pascal, html, CSS, JavaScript and Java.                                                                                                                                                                                                                               ",
        git: "https://github.com/dotainion?tab=repositories",
        linkedIn: "https://www.linkedin.com/in/mallon-blair/",
    }, {
        name: "Richard Jappal",
        role: "Front-End Developer",
        image: richard,
        bio: "I am the IT Administrator and a Facilitator at the Caribbean Coding Academy (CCA). I am also a Junior Software Developer in training. I have experience with the languages: Python, HTML, CSS, Javascript, Pascal, C++, Visual Basic and SQL. I gained most of my knowledge through Caribbean Coding Academy (CCA) Adult Learn to Code where I learned Pascal. Thereafter I moved on to a Bootcamp which I am currently in offered by the company where I learned Python, HTML, CSS, Javascript and SQL.",
        git: "https://github.com/RichardJapal",
        linkedIn: "https://www.linkedin.com/in/richard-japal-7a88911b9/",
    }, {
        name: "Priest Ambrose Sitalo",
        role: "Back-End Developer",
        image: priest,
        bio: "Master degree in Computer Science (Moscow Institute of Physics and Technology). Experience of work 3 years in Russian microprocessor company (Moscow Center of SPARC Technologies) as a software engineer. Very meticulous with task that I do, keen on problem solving, striving to always improve no matter how hard or how easy it is. I have Python, JavaScript, Java, C++, Express.js, hardware/software architecture, TDD, debugging, testing, development pipeline set up and teaching skills.",
        git: "https://github.com/PriestAmbrose",
        linkedIn: "https://www.linkedin.com/in/priest-ambrose-sitalo-2b470324/",
    }, {
        name: "Rockim St. Bernard",
        role: "Front-End Developer",
        image: rockim,
        bio: "I have always been interested in computers and what they can do, especially the software and hardware needed to run them, which is why I have invested time to become a software developer. I specialize in frontend development and expand on any opportunity to learn and improve my skills. My experience in programming languages and frameworks: Python, JavaScript, Java, HTML and CSS, Pascal, and Dart, react and flutter. ",
        git: "https://github.com/RockimSaintBernard",
        linkedIn: "https://www.linkedin.com/in/rockim-st-bernard-25bb75174/",
    },
]