import React from 'react';
import { FaInstagram, FaYoutube, FaFacebook, FaLinkedin } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { footer } from '../contents/footer';



export const PublicFooter = () =>{
    const navigate = useNavigate();
    return(
        <div className='footer-container'>
            <div className='footer-header'>
                <span>Caribbean Coding Academy</span>
                <FaInstagram className='footer-header-icon' /> 
                <FaYoutube className='footer-header-icon' /> 
                <FaFacebook className='footer-header-icon' /> 
                <FaLinkedin className='footer-header-icon'  />
            </div>
            <div className='footer-content-container'>
                <div>
                    <header>Contact Us</header>
                    {footer.contacts.map((val, key)=> <div onClick={()=>navigate(val.route)} className={val.css} key={key}>{val.title}</div> )}
                </div>
                <div>
                    <header>People</header>
                    {footer.people.map((val, key)=> <div onClick={()=>navigate(val.route)} className={val.css} key={key}>{val.title}</div> )}
                </div>
                <div>
                    <header>Programs</header>
                    {footer.programs.map((val, key)=> <div onClick={()=>navigate(val.route)} className={val.css} key={key}>{val.title}</div> )}
                </div>
                <div>
                    <header>Projects</header>
                    {footer.projects.map((val, key)=> <div onClick={()=>navigate(val.route)} className={val.css} key={key}>{val.title}</div> )}
                </div>
            </div>
            <div className='footer-copyright'>Copyright 2022  Caribbean Coding Academy. All Rights Reserved. </div>
        </div>
    )
}