import React from 'react';
import logo from '../../images/CCA-Logo.png';
import { CgWebsite } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes/Routes';

export const PrivateLayout = ({children}) =>{
    const navigate = useNavigate();

    const menus = [
        {
            title: 'Prospects', 
            route: routes.administration().nested().prospects()
        },
    ];

    return(
        <div className="d-flex vh-100">
            <div className="opacity-white-25 h-100 border-end">
                <div className="user-select-none text-primary text-nowrap">
                    <div onClick={()=>navigate(routes.home())} className="d-flex align-items-center admin-website-link px-4 py-2 border-bottom pointer">
                        <img src={logo} alt="" style={{width: '20px'}} />
                        <div className="mx-2">To CCA website</div>
                        <CgWebsite/>
                    </div>
                    {menus.map((menu, key)=>(
                        <div onClick={()=>navigate(menu.route)} className="opacity-white-25 item-hover px-4 py-2 border-bottom pointer" key={key}>
                            <div>{menu.title}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-100">
                {children}
            </div>
        </div>
    )
}