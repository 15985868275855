class Administration{
    _nested = '';
    default = () => this._nested + '/administration/*';
    home = () => this._nested + 'admin/home';
    login = () => this._nested + 'login';
    prospects = () => this._nested + 'prospects';
    nested = () => {
        this._nested = this.default().replace('*', '');
        return this;
    }
}

class Routes{
    invalidPaths = () => '*';
    default = () => '/';
    home = () => '/home';
    projects = () => '/projects';
    teams = () => '/teams';
    services = () => '/services';
    moodle = () => '/moodle';
    contact = () => '/contact';
    developers = () => '/developers';
    administration = () => {
        const route = new Administration();
        route._nested = '';
        return route;
    }
}


export const routes = new Routes();
