import React, { useEffect, useRef, useState } from 'react';
import SlideRoutes from 'react-slide-routes';
import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import $ from 'jquery';


export const AnimatedPage = ({children}) =>{

    useEffect(()=>{
        
    }, []);
    return(
        <div className="animation-page">
            {children}
        </div>
    )
}





















