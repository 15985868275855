import React, { useEffect, useRef, useState } from "react";
import { MdOutlineArrowRight, MdOutlineArrowLeft } from 'react-icons/md';
import $ from 'jquery';
import { tools } from "../tools/Tools";
import { InputEntry } from "../widgets/InputEntry";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../requests/Api";


export const ApplyAndEventsSlideout = () =>{
    const [successMessage, setSuccessMessge] = useState();
    const [errorMessage, setErrorMessge] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    const parentRef = useRef();
    const appyRef = useRef();
    const eventsRef = useRef();
    const btnParentRef = useRef();
    
    const nameRef = useRef();
    const contactRef = useRef();
    const emailRef = useRef();
    const programRef = useRef();

    const validate = () =>{
        let isValid = true;
        if (! nameRef.current.value){
            isValid = false;
            nameRef.current.style.border = '1px solid red';
        }
        if (! contactRef.current.value){
            isValid = false;
            contactRef.current.style.border = '1px solid red';
        }
        if (! programRef.current.value){
            isValid = false;
            programRef.current.style.border = '1px solid red';
        }
        if (! tools.isValid.email(emailRef.current.value)){
            isValid = false;
            emailRef.current.style.border = '1px solid red';
        }
        return isValid;
    }

    const sendEmail = () =>{
        if (!validate()) return;
        const data = {
            firstName: nameRef.current.value,
            phone: contactRef.current.value,
            email: emailRef.current.value,
            message: 'Program interested in is: ' + programRef.current.value
        }
        api.user.createProspect(data).then((response)=>{
            setSuccessMessge('Request sent.');
        }).catch((error)=>{
            setErrorMessge(tools.error.message(error));
        });
    }

    const display = (ref, e) =>{
        e.stopPropagation();
        let allHidden = true;
        $(parentRef.current).children().each((_, child)=>{
            if ($(child).attr('data-active') != 'true' && child == ref){
                $(child).attr('data-active', true).show('fast');
                allHidden = false;
            }else{
                $(child).attr('data-active', false).hide('fast');
            }
        });
        $(btnParentRef.current).children().each((_, child)=>{
            if (e.target == child && !allHidden){
                $(child).find('svg[data-arrow=left]').show();
                $(child).find('svg[data-arrow=right]').hide();
                $(child).css({backgroundColor: 'var(--secondary-color)'});
            }else{
                $(child).find('svg[data-arrow=left]').hide();
                $(child).find('svg[data-arrow=right]').show();
                $(child).css({backgroundColor: ''});
            }
        });
        allHidden ? $(parentRef.current).hide('fast') : $(parentRef.current).show('fast');
    }

    const options = [
        {
            title: 'APPLY',
            ref: appyRef.current,
        },{
            title: 'EVENTS',
            ref: eventsRef.current,
        }
    ];

    const programOffers = [
        'Coding'
    ];

    useEffect(()=>{
        navigate(location.pathname);
        $(document).click(()=>{
            $(parentRef.current).hide('fast');
            $(parentRef.current).children().each((_, child)=>{
                $(child).attr('data-active', false).hide('fast');
            });
            $(btnParentRef.current).children().each((_, child)=>{
                $(child).find('svg[data-arrow=left]').hide();
                $(child).find('svg[data-arrow=right]').show();
                $(child).css({backgroundColor: ''});
            });
            nameRef.current.value = '';
            contactRef.current.value = '';
            emailRef.current.value = '';
            programRef.current.value = '';
        });
    }, []);
    return(
        <div className="option-slide" onClick={(e)=>e.stopPropagation()}>
            <div ref={parentRef} className="option-slide-content" style={{display: 'none'}}>
                <div ref={appyRef} className="option-slide-child" style={{display: 'none'}}>
                    <p><b>CCA offers a wide range of programs and job opportunities. Apply today</b></p>
                    <p style={{marginBottom: '5px', fontSize: '13px'}}>Submit your information and someone will contact you as soon as posible.</p>
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <div style={{marginTop: '25px'}}>
                        <InputEntry inputRef={nameRef} title="Name" />
                        <InputEntry inputRef={contactRef} title="Contact" />
                        <InputEntry inputRef={emailRef} title="Email" />
                        <div>Choose a Program</div>
                        <select ref={programRef} onKeyUp={e=>e.target.style.border = ''} >
                            {programOffers.map((offer, key)=>(
                                <option key={key}>{offer}</option>
                            ))}
                        </select>
                    </div>
                    <div className="optioni-slide-btn-action-container">
                        <button onClick={sendEmail}>Go</button>
                    </div>
                </div>
                <div ref={eventsRef} className="option-slide-child" style={{display: 'none'}}>
                    <p>No events</p>
                </div>
            </div>
            <div ref={btnParentRef} className="option-slide-btn">
                {options.map((opt, key)=>(
                    <div 
                        onClick={(e)=>display(opt?.ref, e)} 
                        style={{backgroundColor: $(opt?.ref).attr('data-active') == 'true' && 'var(--secondary-color)'}} 
                        key={key}
                    >
                        {opt.title}
                        <MdOutlineArrowLeft style={{display: 'none'}} data-arrow="left" />
                        <MdOutlineArrowRight data-arrow="right" />
                    </div>
                ))}
            </div>
        </div>
    )
}