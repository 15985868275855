import React, { useEffect, useRef, Component } from 'react';
import { ImBubbles2 } from 'react-icons/im';
import { BsFacebook } from 'react-icons/bs';
import { FacebookProvider, Like, Share, ShareButton } from 'react-facebook';
import $ from 'jquery';


export const FacebookFeed = () =>{

    const onFindUs = () =>{
        window.open("https://www.facebook.com/caribbeancodingacademy/", "_blanck");
    }

    const onSendMessage = () =>{
        window.open("https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F207513453327209%2F%3Fmessaging_source%3Dsource%253Apages%253Apage_plugin_message", "_black");
    }

    const onLiked = () =>{
        return;//this is still being figured out..
        const url = "https://www.facebook.com/v2.12/plugins/error/confirm/page?iframe_referer=https%3A%2F%2Fcomponents.mywebsitebuilder.com%2F&kid_directed_site=false&secure=true&plugin=page&return_params=%7B%22app_id%22%3A%22744137685644053%22%2C%22channel%22%3A%22https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dfa30a0826c7a4%26domain%3Dcomponents.mywebsitebuilder.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fcomponents.mywebsitebuilder.com%252Ffa22f84e986a08%26relation%3Dparent.parent%22%2C%22container_width%22%3A%22473%22%2C%22height%22%3A%22494%22%2C%22hide_cover%22%3A%22true%22%2C%22href%22%3A%22https%3A%2F%2Ffacebook.com%2Fcaribbeancodingacademy%22%2C%22locale%22%3A%22en_GB%22%2C%22sdk%22%3A%22joey%22%2C%22show_facepile%22%3A%22true%22%2C%22show_posts%22%3A%22true%22%2C%22width%22%3A%22473%22%2C%22ret%22%3A%22sentry%22%2C%22act%22%3Anull%7D"
        window.open(url, "_blanck", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=500,width=400,height=700");
    }

    useEffect(()=>{
        const script = document.createElement("script");
        script.setAttribute("src", "https://www.powr.io/powr.js?platform=html");
        const headTag = document.getElementsByTagName("head")[0];
        headTag.appendChild(script);
    }, []);
    return(
        <div className="facebook-feed-container">
            <div className="facebook-feed-sub-container">
                <div className="powr-facebook-feed" id="4cc8e256_1642896774">
                    <div className="facebook-feed-header">
                        <span style={{position: "relative"}}>
                            <span>Social Media Feed</span>
                            <div className="facebook-feed-btn-container">
                                <button onClick={onFindUs} className="facebook-feed-btn"><BsFacebook /> Find Us</button>
                                <button hidden onClick={onLiked} className="facebook-feed-btn"><BsFacebook /> Like Page</button>
                                <button onClick={onSendMessage} className="facebook-feed-btn"><ImBubbles2 /> Message</button>
                            </div>
                        </span>
                    </div>
                    <div className="facebook-feed-footer"></div>
                </div>
            </div>
        </div>
    )
}