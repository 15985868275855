import React, { useEffect, useState } from "react";
import { PrivateLayout } from "../layouts/PrivateLayout";
import { FilterOption } from "../../components/FilterOption";
import { api } from "../../requests/Api";

export const Prospects = () =>{
    const [prospect, setProspect] = useState();
    const [prospects, setProspects] = useState([]);

    const onSelect = (user) =>{
        setProspect(user);
    }

    useEffect(()=>{
        api.user.listProspects().then((response)=>{
            setProspects(response.data.data);
        }).catch((error)=>{

        });
    }, []);
    
    return(
        <PrivateLayout>
            <FilterOption/>
            <div className="prospects-main-container d-flex w-100 h-100 p-5 pt-0 mt-2">
                <div className="bg-transparent border border-end-0 h-100 overflow-auto px-2 text-nowrap">
                    {prospects.map((user, key)=>(
                        <div onClick={()=>onSelect(user)} className="item-hover border-bottom d-flex align-items-center pointer" key={key}>
                            <div className="pe-1">{key+1}.</div>
                            <div className="text-truncate py-2 ps-3">{user?.attributes?.firstName} {prospect?.attributes?.lastName}</div>
                        </div>
                    ))}
                </div>
                <div className="border w-100 h-100 overflow-auto">
                    <div className="fw-bold fs-3 bg-light px-3 py-2 border-bottom">Prospect</div>
                    {
                        prospect ?
                        <div className="container">
                            <div className="mt-2">
                                <div>First Name:</div>
                                <div className="form-control p-2">{prospect?.attributes?.firstName}</div>
                            </div>
                            <div className="mt-2">
                                <div>Last Name:</div>
                                <div className="form-control p-2">{prospect?.attributes?.lastName}</div>
                            </div>
                            <div className="mt-2">
                                <div>Email:</div>
                                <div className="form-control p-2">{prospect?.attributes?.email}</div>
                            </div>
                            <div className="mt-2">
                                <div>Phone:</div>
                                <div className="form-control p-2">{prospect?.attributes?.phone}</div>
                            </div>
                            <div className="mt-2">
                                <div>Message:</div>
                                <div className="form-control p-2">{prospect?.attributes?.message}</div>
                            </div>
                        </div>: 
                        <div className="mt-2 fw-bold alert alert-primary">No Prospects</div>
                    }
                </div>
            </div>
        </PrivateLayout>
    )
}