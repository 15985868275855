import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { VscMenu } from 'react-icons/vsc';
import $ from 'jquery';
import { navigationList } from '../contents/header';
import { AdminOptionMenu } from '../../components/AdminOptionMenu';


export const NavBar = ({navBarRef, stickToTop}) => {
    const [slowMobileNav, setShowMobileNav] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const navRef = useRef();
    const backdropRef = useRef();
    const isMobileView = useRef();
    const navContainerRef = useRef();

    const tiggleMobileNav = () => {
        if (slowMobileNav) {
            $(navRef.current).show("slow");
            $(backdropRef.current).show("slow");
        } else {
            $(navRef.current).hide("slow");
            $(backdropRef.current).hide("slow");
        }
        setShowMobileNav(!slowMobileNav);
    }

    const onNav = (path) => {
        navigate(path);
        isMobileView.current && tiggleMobileNav();
    }

    useEffect(() => {
        isMobileView.current = false;
        $(window).resize(() => {
            if ($(window).width() > 767) {
                $(navRef.current).show("slow");
                isMobileView.current = false;
            } else isMobileView.current = true;
        });

        if(stickToTop) return;

        $("#root").scroll((e) => {
            if (e.target.scrollTop >= 350) $(navBarRef?.current || navContainerRef.current).addClass("header-nav-animate");
            else $(navBarRef?.current || navContainerRef.current).removeClass("header-nav-animate");
        });
    }, []);

    useEffect(() => {
        if(!stickToTop) return;
        $(navContainerRef.current).attr('class', 'header-nav-container header-nav-animate');
    }, [stickToTop, location]);

    return ( 
        <nav ref={navBarRef || navContainerRef} className="header-nav-container" style={{position: stickToTop ? 'relative' : null}}>
            <div className='header-nav-logo' >
                <AdminOptionMenu/>
            </div> 
            <div ref={navRef} className = 'header-nav-links' > 
                {navigationList.map((nav, key) => ( 
                    <a onClick={() => onNav(nav.href)} style={{color: location.pathname === nav.href && 'var(--secondary-color)'}} key={key}> 
                        {nav.title} 
                    </a>
                ))} 
            </div> 
            <div onClick={tiggleMobileNav} className='header-nav-mobile-container'>
                <VscMenu className='header-nav-mobile-menu'/>
                <div ref={backdropRef} className='header-nav-mobile-backdrop'></div> 
            </div> 
        </nav> 
    )
}