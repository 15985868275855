import React from 'react';
import { Route, Navigate, HashRouter } from "react-router-dom";
import SlideRoutes from 'react-slide-routes';

//themes
import './themes/general.css';
import './themes/animation.css';
import './themes/index.css';
import './themes/responsive.css';
import './themes/variables.css';
import 'bootstrap/dist/css/bootstrap.css';

//routers
import { routes } from './routes/Routes'
import { PageNotFound } from './error/ui/PageNotFound';
import { Home } from './public/pages/Home';
import { Projects } from './public/pages/Projects';
import { Teams } from './public/pages/Teams';
import { Services } from './public/pages/Services';
import { Moodle } from './public/pages/Moodle';
import { Contact } from './public/pages/Contact';
import { Developers } from './public/pages/Developers';
import { StateManagement } from './provider/StateManagement';
import { PrivateRouter } from './private/router/PrivateRouter';

const App = () => {
    return ( 
        <HashRouter >
            <StateManagement>
                <SlideRoutes duration={1000} timing="ease-in-out" >
                    <Route path={routes.home()} element={<Home/>}/>
                    <Route path={routes.projects()} element={<Projects/>}/>
                    <Route path={routes.teams()} element={<Teams/>}/> 
                    <Route path={routes.services()} element={<Services/>}/> 
                    <Route path={routes.moodle()} element={<Moodle/>}/>
                    <Route path={routes.contact()} element={<Contact/>}/>
                    <Route path={routes.developers()} element={<Developers/>}/>
                    <Route path={routes.default()} element={<Navigate to={routes.home()}/>}/> 
                    <Route path={routes.administration().default()} element={<PrivateRouter/>}/>
                    <Route path={routes.invalidPaths()} element={<PageNotFound/>}/>   
                </SlideRoutes> 
            </StateManagement> 
        </HashRouter>
    );
}

export default App;