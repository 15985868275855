import { createContext, useContext, useEffect, useRef, useState } from "react";
import { api } from "../requests/Api";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/Routes";
import { token } from "../tools/Token";

const ContextProvider = createContext();
export const useAuth = () => useContext(ContextProvider);

export const AuthProvider = ({children}) =>{
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const timeoutRef = useRef();

    const toAdministration = () =>{
        navigate(routes.administration().nested().home());
    }

    const login = (data) =>{
        api.login.login(data).then((response)=>{
            setUser(response.data.data[0]);
            token.set(response.data.data[0].attributes.token);
            toAdministration();
        }).catch((error)=>{

        });
    }

    useEffect(()=>{
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            api.login.session().then((response)=>{
                setUser(response.data.data[0]);
            }).catch((error)=>{

            }).finally(()=>{
                setLoading(false);
            });
        }, 100);
    }, []);

    const providerValue = {
        user,
        login
    }

    return(
        <ContextProvider.Provider value={providerValue}>
            {!loading && children}
        </ContextProvider.Provider>
    )
}