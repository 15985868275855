import { routes } from '../../routes/Routes';
export const footer = {
    contacts: [
        {
            title: 'H. A Blaze Street, St. George, Grenada',
            css: '',
            route: '#',
        },{
            title: '+1 (473) 456 1568',
            css: '',
            route: '#',
        },{
            title: 'caribbeancodingacademy@gmail.com',
            css: '',
            route: '#',
        },{
            title: 'Contact',
            css: '',
            route: '#',
        }
    ],
    people: [
        {
            title: 'Teams',
            css: '',
            route: routes.teams(),
        },{
            title: 'Developers',
            css: '',
            route: '#',
        }
    ],
    programs: [
        {
            title: 'Projects',
            css: '',
            route: '#',
        },{
            title: 'Moodle',
            css: '',
            route: '#',
        },{
            title: 'Services',
            css: '',
            route: routes.services(),
        }
    ],
    projects: [
        {
            title: 'Future Projects',
            css: '',
            route: '#',
        },
    ]
}