import React, { useEffect, useRef } from "react";
import $ from 'jquery';


export const InputEntry = ({title, onChange, inputRef, rows, placeholder, required, type}) =>{
    const inputEntryRef = useRef();
    const titleRef = useRef();

    useEffect(()=>{
        if(rows) return $(titleRef.current).css({top: '-13px', padding: 0});
        $(inputRef?.current || inputEntryRef.current).on('focus', (e)=>{
            $(titleRef.current).addClass('input-enter-overlay').css({color: ''});
            $(e.target).css({textAlign: 'left'});
        }).on('blur', (e)=>{
            if(e.target.value) return;
            $(titleRef.current).removeClass('input-enter-overlay').css({color: 'black'});
            $(e.target).css({textAlign: ''});
        }).keyup((e)=>{
            $(e.target).parent().css({border: ''});
        });
        $(titleRef.current).on('click', ()=>{
            $(inputRef?.current || inputEntryRef.current).trigger('focus').on('focus');
        });
        $(titleRef.current).css({color: 'black'});
    }, []);
    return(
        <div className="input-entry">
            <div ref={titleRef}>{title}{required && <span>*</span>}</div>
            {
                rows
                    ? <textarea 
                        ref={inputRef || inputEntryRef} 
                        onChange={onChange} 
                        rows={rows} 
                        placeholder={placeholder}
                        style={{textAlign: 'left'}}
                        required={required}
                    />
                    : <input 
                        ref={inputRef || inputEntryRef} 
                        onChange={onChange} 
                        placeholder={placeholder} 
                        required={required}
                        type={type}
                    />
            }
        </div>
    )
}