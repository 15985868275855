
export class User{
    constructor(API){
        this.api = API;
    }

    async createProspect(data){
        return await this.api.post('/create/prospect', data);
    }

    async listProspects(data){
        return await this.api.post('/list/prospects', null);
    }
}