import React from "react";
import {useNavigate} from "react-router-dom"
import {routes} from  "../../routes/Routes"


export const PageNotFound = () =>{
    const navigate = useNavigate();

    const onBackToHome = () =>{
        navigate(routes.home());
    }
    
    return(
        <div className="error404">
            <div>
                <h1> 404 </h1>
                <p>Page Not Found</p>
                <button type="button" onClick={onBackToHome} >Go back!</button>
            </div>
        </div>
    )
}