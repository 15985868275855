import cliffort from '../../images/clifford.jpg';
import carlos from '../../images/carlos.jpg';
import gillian from '../../images/gillian.jpg';
import dr_matthew from '../../images/dr-mathew.jpg';
import keith from '../../images/keith.jpeg';
import donna from '../../images/donna.jpeg';

export const teamContent = {
    aim: `
        The CCA aims to teach our candidates all the necessary skills that they would need to work in a specific area within the software or Information Technology industry.
    `,
    objective: `
        The CCA program has some key objectives. The aim for us is to teach the professional all the skills he would need to work in a specific area within the software development field. Therefore, a proper evaluation or assessment of the candidate would be made before being accepted into the program:
        Train students for entry level software positions around the world
        Develop individualized curriculum for each student
        Assessment skill based platform to qualify the best students
        Develop Student Business Skills
        Ability to recruit and train marginalized people around the world
        Bridge Program for college graduates to find employment in the information technology field
        These are the key objectives of the program that we think can change our society for the better.
        The CCA will work tirelessly to ensure that all these objectives are met.
    `,
}

export const teamMembers = [{
    name: "Clifford Browne",
    role: "Managing Director",
    image: cliffort,
}, {
    name: "Carlos Gittens",
    role: "Academic Director",
    image: carlos,
}, {
    name: "George Matthew",
    role: "Academic Director",
    image: dr_matthew,
}, {
    name: "Donna Mcintosh",
    role: "Marketing Director",
    image: donna,
}, {
    name: "Keith Ashby",
    role: "Director",
    image: keith,
}, ]