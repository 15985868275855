import { routes } from '../../routes/Routes';



export const navigationList = [
    { title: "Home", href: routes.home() },
    { title: "Projects", href: routes.projects() },
    { title: "Team", href: routes.teams() },
    { title: "Services", href: routes.services() },
    { title: "Moodle", href: routes.moodle() },
    { title: "Contact", href: routes.contact() },
    { title: "Developers", href: routes.developers() },
]

export const headerPageInfo = {
    home: {
        title: 'Course with Job training and possible employment offered by C.C.A',
        list: [
            'Software Developer Programs',
            'Certified Fiber Optics Courses',
            'Training in Web Development',
            'Training in Amimation, Graphic Video Editing',
            'Sales Force Administration Certification Courses',
            'Robotic Process Automation Martitime Training and Certification'
        ],
    },
    projects: {
        title: "Our learning style requires our bootcampers to work on various projects during their learning process.",
        list: []
    },
    services: {
        title: '',
        list: []
    },
    teams: {
        title: '',
        list: []
    },
    developers: {
        title: '',
        list: [],
    },
};