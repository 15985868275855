import React, { useEffect, useRef, useState } from 'react'
import { FaInstagram, FaYoutube, FaFacebook, FaLinkedin } from 'react-icons/fa';
import { AiOutlineMail, AiOutlineCloseCircle } from 'react-icons/ai';
import { BsShareFill } from 'react-icons/bs';
import { tools } from '../tools/Tools';
import { useStateManagement } from '../provider/StateManagement';
import $ from 'jquery';


export const ShareSolideout = () =>{
    const {sharePopup, setSharePopup} = useStateManagement();
    
    const share = (name) =>{
        navigator.share({
            title: document.title,
            text: `${name} invite you to try this application.`,
            url: window.location.origin,
        }).then(()=>{}).catch(()=>{});
    }

    const shareOnFacebook = () =>{
        window.open('https://facebook.com/login/identify', '_blank');
    }

    useEffect(()=>{
        
    }, []);
    return(
        <div className="card-popup">
            <div className="card-popup-child" onClick={share}>
                <div data-svg-c><BsShareFill/></div>
                <div data-content>Share with colleagues</div>
            </div>
        </div>
    )
}