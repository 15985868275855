import { FaDesktop, FaLaptopCode, FaFileCode, FaChalkboardTeacher } from 'react-icons/fa';
import { GiDeliveryDrone } from 'react-icons/gi';


export const servicesObjective = `
    Our leraning style requires our bootcampers to work on various projects during their learninng process. Here are a few of those projects we're currently working on more to follow soon
`

export const services = [
    {
        title: "Kids learn to code",
        subject: `
        The junior program stresses a mild and fun introduction to software application development in such areas as robotics, game programming and UAV development.   The candidate is taught basic programming and instruction for managing the applications.
        The senion program consists of an introduction to structured program languages such as Python, PhP and JavaScript.  At the end of the program, the candidate is expected to have basic understanding of the subject area.  The program will stress more advanced projects in the areas of Game Programming, Robotics and UAV applications.
        `,
        icon: FaLaptopCode
    },{
        title: "Adult Learn to Code",
        subject: `
        Candidates are expected to achieve basic to Intermediate development of skills.  At the end of the program, the student should have the skills to be able to train for a specific job or job category.   This program is geared towards a candidate that has a high school level proficiency in english comprehension and mathematics.  The program will stress critical thinking and develop basic and intermediate level understanding of software development or information technology proficiency based on the job requirements..   The candidate will be expected to learn two of the following languages and/or frameworks such as Python, Ruby, PhP, Javascript using frameworks such as Angular,React, Node, RoR, Django, Flask, Zend, RoR. Students may be expected to do remedial coursework in order to attain Level 1 or 2 proficiency in the area of study. The center takes a holistic approach in preparing candidates for the job market.  It not just technical proficiency but for the academy the mission is to develop the near ideal candidate for a position based on employer requirements.
        `,
        icon: FaDesktop
    },{
        title: "Bootcamp",
        subject: `
        The technical program requirements may vary based on job description or employer requirements for each candidate.  However, there are many core proficiency requirements that the academy expects the candidate to master on completion of program.   Note, candidates have to an agreement for placement in order to be accepted into the program.
        `,
        icon: FaFileCode
    },{
        title: "Drone",
        subject: `
        More information coming soon.
        `,
        icon: GiDeliveryDrone
    },{
        title: "Business Incubator",
        subject: `
        More information coming soon.
        `,
        icon: FaChalkboardTeacher
    }
]