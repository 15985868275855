import React, { useRef } from "react";
import logo from '../../images/CCA-Logo.png';
import { useAuth } from "../../provider/AuthProvider";

export const Login = () =>{
    const { login } = useAuth();

    const emailRef = useRef();
    const passwordRef = useRef();

    const onSubmit = (e) =>{
        e.preventDefault();
        const data = {
            email: emailRef.current.value,
            password: passwordRef.current.value
        }
        login(data);
    }
    
    return(
        <div className="mx-3">
            <div className="col-md-4 col-lg-2 col-6">
                <img className="w-100 h-100" src={logo} alt="" />
            </div>
            <div className="border p-3 shadow-sm opacity-white-25 col-md-7 col-lg-5 col-12 m-auto">
                <form onSubmit={onSubmit} className="w-100" style={{top: '15%'}}>
                    <div className="signin-input-group">
                        <input ref={emailRef} className="form-control p-2" placeholder="Email Address" type="email" required />
                    </div>
                    <div className="signin-input-group mt-3">
                        <input ref={passwordRef} className="form-control p-2" placeholder="Password" type="password" required />
                    </div>
                    <div className="mt-3">
                        <button className="btn bg-white border p-2 px-4" type="submit">LOG IN</button>
                    </div>
                </form>
            </div>
        </div>
    )
}