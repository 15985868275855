import React from 'react';
import defaultImg from '../images/profile-default.png';
import {FaGitSquare, FaLinkedin} from 'react-icons/fa'

const handleClick = (click) => { 
    window.open(click, "_blank")
}

export const DevelopersCardList = ({developers, title}) =>{
    return(
        <div className="developers-members">
            <h1>{title}</h1>
            {developers?.map?.((member, key)=>(
                <div className="developers-members-card" key={key}>
                    <img className="developers-members-image" src={member.image || defaultImg} alt="" draggable={false} />
                    <div className="developers-members-name">{member.name}</div>
                    <div className="developers-members-role">{member.role}</div>
                    <div className="developers-members-bio">{member.bio}</div>
                    <span>
                        <FaGitSquare className="developers-members-icons" onClick={()=> handleClick(member.git)}/>
                        <FaLinkedin className="developers-members-icons" onClick={()=>handleClick(member.linkedIn)}/>
                    </span>
                </div>
            ))} 
        </div>
    )
}