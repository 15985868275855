import React from "react";
import logo from '../images/CCA-Logo.png';
import { routes } from "../routes/Routes";
import { useNavigate } from "react-router-dom";

export const AdminOptionMenu = ({children}) =>{
    const navigate = useNavigate();

    const toPrivate = () =>{
        navigate(routes.administration().nested().home());
    }
    
    return(
        <div className="header-nav-logo admin-option-menu">
            <img src={logo} alt="" draggable={false}/> 
            <div className="admin-option-menu-overlay">
                <div onClick={()=>toPrivate()} className="admin-option-menu-overlay-item">Administration</div>
            </div>
        </div>
    )
}