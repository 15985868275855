import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplyAndEventsSlideout } from '../../ads/ApplyAndEventsSlideout';
import { ShareSolideout } from '../../ads/ShareSolideout';
import { PublicFooter } from './PublicFooter';
import { PublicHeader } from './PublicHeader';
import { AnimatedPage } from '../../animations/AnimatedPage';
import $ from 'jquery';
import { headerPageInfo } from '../contents/header';


export const PublicLayout = ({children}) =>{
    const [pageInfo, setPageInfo] = useState({header: null, info: null});
    const location = useLocation();

    useEffect(()=>{
        Object.keys(headerPageInfo).forEach((key)=>{
            if (location.pathname.includes(key)){
                setPageInfo({
                    header: headerPageInfo?.[key]?.title || null, 
                    info: headerPageInfo?.[key]?.list || null
                });
            }
        });
    }, [location]);
    return(
        <AnimatedPage>
            <ShareSolideout/>
            <ApplyAndEventsSlideout/>
            <PublicHeader 
                infoHeader={pageInfo.header} 
                infoList={pageInfo.info} 
            />
            {children}
            <PublicFooter/>
        </AnimatedPage>
    )
}