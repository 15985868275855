import $ from 'jquery';
import emailJs from '@emailjs/browser';

export class InViewAnimations{
    //element can be jsx, css className, id or useRef eleemnt; eg: ".someClassName" || "#someId" || ref.current || <div/>
    timeoutRef = null;
    animationExecutions = [];

    constructor(){
        $("#root").scroll(()=>{
            this.animationExecutions.forEach((obj)=> {
                this.init()
            });
        });
        $(window).scroll(()=>{
            this.init()
        });
    }

    init(){
        this.animationExecutions.forEach((obj)=> {
            try{
                obj.animate();
            }catch{}
        });
    }

    run(element, css){
        this.animationExecutions.push({
            animate: ()=>{
                let elementTop = $(element).offset().top;
                let elementBottom = (elementTop + $(element).outerHeight()) - $(element).height();
                elementTop = elementTop + $(element).height();

                let viewportTop = $(window).scrollTop();
                let viewportBottom = viewportTop + $(window).height();

                clearTimeout(this.timeoutRef);

                if (elementBottom > viewportTop && elementTop < viewportBottom){
                    $(element).addClass(css);
                } else {
                    this.timeoutRef = setTimeout(() => {
                        $(element).removeClass(css);
                    }, 100);
                }
            }
        });
        return this;
    }
}

class Validate{
    email(email){
        const regix = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (regix.test(email)) return true;
        return false;
    }
}

class ErrorResponseHandler{
    message(error){
        try{
            if(error?.response?.data?.error){
                return error.response.data.error.message;
            }
            if(typeof error === 'string' || error instanceof String){
                return error;
            }
            if(error?.response?.data){
                throw new Error('Response receive with incorrect error format.');
            }
            throw new Error('ErrorResponseHandler receive data and dont know what to do with it.');
        }catch(err){
            return err.message;
        }
    }

    meta(error){
        if(error?.response?.data?.error?.meta){
            return error.response.data.error.meta;
        }
        return null;
    }
}

export class Tools{
    timeoutRef = null;
    constructor(){
        this.isValid = new Validate();
        this.error = new ErrorResponseHandler();
    }

    jump(element, times, distance, speed){
        $(element).stop();
        for(var i = 0; i < times; i++) {
            $(element).animate({marginTop: '-=' + distance +"px", height: '-='+ distance +"px"}, speed)
                .animate({marginTop: '+='+distance, height: '+='+ distance +"px"}, speed);
        } 
    }
    
    bounce(element, times, distance, speed){
        this.jump(element, times, distance, speed);
        $("#root").scroll(()=>{
            clearTimeout(this.timeoutRef);
            this.timeoutRef = setTimeout(() => {
                this.jump(element, times, distance, speed);
            }, 800);
        })                
    }

    inView(element, css){
        return new InViewAnimations().run(element, css)
    }
}

export const tools = new Tools();