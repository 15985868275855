import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "../../routes/Routes";
import { Login } from "../pages/Login";
import { AuthProvider, useAuth } from "../../provider/AuthProvider";
import { Prospects } from "../pages/Prospects";
import { AdminHome } from "../pages/AdminHome";

export const PrivateRouter = () =>{
    return(
        <AuthProvider>
            <Routes>
                <Route path={routes.administration().prospects()} element={<CheckUserAccess element={<Prospects/>}/>}/>
                <Route path={routes.administration().home()} element={<CheckUserAccess element={<AdminHome/>}/>}/>
                <Route path={routes.administration().login()} element={<Login/>}/>
                <Route path={'*'} element={<Navigate to={routes.administration().home()}/>}/>
            </Routes>
        </AuthProvider>
    )
}

const CheckUserAccess = ({element}) =>{
    const { user } = useAuth();
    if(!user){ 
        console.log(routes.administration().login());
        return <Navigate to={routes.administration().nested().login()}/>;
    }
    return(element);
}