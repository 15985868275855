import React, { useEffect, useRef, useState } from 'react';
import logo from '../../images/CCA-Logo.png';
import defaulImage from '../../images/profile-default.png';
import homeImg from '../../images/home-nav-image.png';
import projectImg from '../../images/project-nav-image.png';
import teamImg from '../../images/team-nav-image.png';
import serviceImg from '../../images/service-nav-image.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../routes/Routes';
import $ from 'jquery';
import introductionVideo from '../../videos/introduction.mp4';
import bg from '../../images/bg.jpg';
import bg2 from '../../images/bg2.jpg';
import bg3 from '../../images/bg3.jpg';
import bg4 from '../../images/bg4.jpg';
import bg5 from '../../images/bg5.png'
import { NavBar } from './NavBar';

export const PublicHeader = ({ infoHeader, infoList }) => {
    const [contentInfo, setContentInfo] = useState(null);
    const [navImage, setNavImage] = useState({ icon: null, background: null });

    const navigate = useNavigate();
    const location = useLocation();

    const navRef = useRef();

    useEffect(() => {
        let letter = location.pathname.substring(2, 1).replace("/", "");
        let subStr = location.pathname.substring(2, location.pathname.length);
        const contents = letter.toUpperCase() + subStr

        if (location.pathname.includes(routes.home())) {
            setContentInfo(null);
            setNavImage({ icon: homeImg, background: navImage.background });
            $(navRef.current).removeClass("header-nav-animate-style-on-no-bg-header");
        } 
        else if (location.pathname.includes(routes.projects())) {
            setContentInfo(contents);
            setNavImage({ icon: serviceImg, background: bg5 });
            $(navRef.current).addClass("header-nav-animate-style-on-no-bg-header");
        }
        else if (location.pathname.includes(routes.services())) {
            setContentInfo(contents);
            setNavImage({ icon: serviceImg, background: bg });
            $(navRef.current).addClass("header-nav-animate-style-on-no-bg-header");
        } 
        else if (location.pathname.includes(routes.teams())) {
            setContentInfo(contents);
            setNavImage({ icon: teamImg, background: bg2 });
            $(navRef.current).addClass("header-nav-animate-style-on-no-bg-header");
        } 
        else if (location.pathname.includes(routes.contact())) {
            setContentInfo(contents);
            setNavImage({ icon: teamImg, background: bg3 });
            $(navRef.current).addClass("header-nav-animate-style-on-no-bg-header");
        } 
        else if (location.pathname.includes(routes.developers())) {
            setContentInfo(contents);
            setNavImage({ icon: teamImg, background: bg4 });
            $(navRef.current).addClass("header-nav-animate-style-on-no-bg-header"); 
        }   
        else if (location.pathname.includes("/ project is not there yet")) {
            setContentInfo(contents);
            setNavImage({ icon: projectImg, background: navImage.background });
            $(navRef.current).addClass("header-nav-animate-style-on-no-bg-header");
        } 
        else {
            setNavImage({ icon: defaulImage, background: navImage.background });
        }
    }, [location]);


    return ( 
        <>
            <div className='header-main-container' style={{backgroundImage: `url(${navImage.background})`}}>
                <video hidden={location.pathname !== routes.home()} className='header-video-player' loop autoPlay muted>
                    <source src={introductionVideo}/> 
                </video> 
                <div className='header-header-info' >
                    <div> 
                        <h1>CCA</h1>
                    </div>
                    <div> 
                        <h3>RESPONSE TO <br/> GRENADIANS YOUTHS</h3>
                    </div>
                </div> 
                <NavBar navBarRef={navRef}/>
                <div className='header-nav-content-container'>
                    <div className='header-nav-left-content'>
                        <div>
                            <p>{contentInfo || "Join Us at the Caribbean Coding Academy"}</p> 
                            <span> We offer the latest in technology</span> 
                            <button className='header-nav-contact-btn' onClick={()=>navigate(routes.contact())}>Contact Us</button> 
                        </div> 
                    </div> 
                    <div>
                        <img src={navImage.icon || defaulImage} alt="" draggable={false}/> 
                    </div> 
                </div> 
            </div> 
            <div style={{display: !infoHeader && 'none'}} className='header-nav-content-aim'>
                <div>
                    <h2>
                        <span/>
                        <div>{infoHeader}</div> 
                    </h2> 
                    <ul> {
                            infoList?.map ?.((info, key) => ( 
                                <li key={key}>
                                    <span>{info}</span> 
                                </li>
                            ))
                        } 
                    </ul> 
                </div> 
                <div className='header-nav-content-aim-logo-container'>
                    <img src={logo} alt="" draggable={false}/> 
                    <h3>Motto:</h3> 
                    <p>Preparing our youths for the New Digital Economy.</p> 
                </div> 
            </div> 
        </>
    )
}