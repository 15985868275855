import React, { useEffect, useRef, useState } from "react";
import aboutCcaImg from "../../images/about_cca.png";
import brazenLogo from "../../images/brazenLogo.png";
import { home_info, home_partners, home_service_list } from "../contents/home";
import $ from 'jquery';
import { YoutubeFeed } from "../../components/YoutubeFeed";
import { FacebookFeed } from "../../components/FacebookFeed";
import { Modal } from "../../containers/Modal";
import { PublicLayout } from "../layouts/PublicLayout";
import { tools } from '../../tools/Tools';

//const animate = new InViewAnimations();

let sizeIds = [];
let tiltToggle = false;
export const Home = () => {
    const [modalIsOpen, setModalIsOpen] = useState({state: false, data: null});

    const silzeInit = (index) =>{
        const id = "sizeId-" + index;
        sizeIds.push(id);
        return id;
    }

    const cardTiltToggle = (index) =>{
        tiltToggle = !tiltToggle;
        return tiltToggle;
    }

    useEffect(() => {
        $(window).resize(()=>{
            sizeIds.forEach((id)=>{
                var parent = $('#' + id);
                var child = $(parent).find("div")[0];

                if( $(child).height() >= $(parent).height() ) {
                    $($(parent).parent().find("span")[0]).show("slow");
                }else{
                    $($(parent).parent().find("span")[0]).hide("slow");
                }
            })
        });
        $(window).resize();
        tools.inView(".home-service-tilt", "element-tilt-animation");
        tools.inView(".element-tilt-animation-starter", "element-tilt-animation");
    }, []);
    return (
        <PublicLayout>
            <div className="home-main-container">
                <div className="home-about_cca_container">
                    <div className="home-header">
                        <h1> About CCA </h1>
                    </div>
                    <div className="home-about_cca-wrapper">
                        <div className="home-about_cca_img">
                            <img className="element-tilt-animation-starter element-tilt-animation" src={aboutCcaImg} alt="img" />
                        </div>
                        <div className="home-about_cca_info">
                            <p>{home_info.aboutUs}</p>
                            <p>All our facilitators are I.T graduates from St. George’s University and T.A. Marryshow Community College. We also have facilitators who
                                are certified in their various field of training with experience. Proof of Academic and experience
                                profile are available for viewing on our <span onClick={()=>{}}>website </span>
                                and can be provided on request.</p>
                        </div>
                    </div>
                </div>

                <div className="home-benefit"> 
                    <div style={{backgroundColor: 'rgb(185, 127, 18, 0.1)'}}>
                        <h2>Economic Benefit to Grenada:</h2>
                        <p>{home_info.economicBenefit}</p>
                        <ul>
                            {home_info.economicBenefitList.map((benefit, key) => (
                                <li key={key}>
                                    <p>{benefit}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h2>Benefits to Public and Private Businesses</h2>
                        <p>{home_info.publicPrivateBusinesses}</p>
                    </div>
                    <div style={{backgroundColor: 'rgb(185, 127, 18, 0.1)'}}>
                        <h2>The Risk Analysis</h2>
                        <p>{home_info.riskAnalysis}</p>
                    </div>
                    <div>
                        <h2>Conclusion</h2>
                        <p>{home_info.conclusion}</p>
                    </div>
                </div>

                <div className="home-services_container">
                    <div className="home-header">
                        <h1>Services</h1>
                    </div>
                    <div className="home-services">
                        {home_service_list.map((serv, i) => (
                            <div className={`home-service-card ${cardTiltToggle(i) && 'home-service-tilt'}`} key={i}>
                                <div>
                                    <div id={silzeInit(i)}>
                                        <div>
                                            <img src={serv.img} alt="img" />
                                            <p>{serv.info}</p>
                                        </div>
                                    </div>
                                    <span hidden onClick={()=>setModalIsOpen({state: true, data: serv.info})} >More...</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="home-partners-container">
                    <div className="home-header">
                        <h1> Partners </h1>
                    </div>
                    {home_partners.map((partners, i) => (
                        <div className="home-partners-content" key={i}>
                            <img src={partners.img} alt="img" />
                            <p>{partners.info}</p>
                            <a href={partners.link}>{partners.link}</a>
                        </div>
                    ))}
                </div>
                <FacebookFeed />
                <YoutubeFeed />
                <Modal 
                    isOpen={modalIsOpen.state} 
                    backdropClose
                    onClose={()=>setModalIsOpen({state: false, data: null})}
                >
                    <p>{modalIsOpen.data}</p>
                </Modal>
            </div>
        </PublicLayout>
    )
}