import React, { useEffect, useState } from "react";
import axios from "axios";



const cca = {
    title: 'Caribbean Coding Academy',
    embededUrl: 'https://www.youtube.com/embed/',
    channelId: 'UC07krghGZtvqmWsabwLQGfw',
    link: 'https://www.googleapis.com/youtube/v3/search?key=',
    extLink: '&part=snippet,id&order=date&maxResults=',
    apiKey: process.env.REACT_APP_YOUTUBE_FEED_API_KEY,
    allows: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
}

const fallBackFeeds = ['tprGyYDBLFM', 'teXmkpGqGyM', 'h8RJKAt6YJQ'];

export const YoutubeFeed = () =>{
    const [feeds, setFeeds] = useState([]);

    const embedUrl = (results = [])=>{
        setFeeds(results.map(obj=> cca.embededUrl + obj?.id?.videoId));
    }    

    const builtUrl = (maxResults = 6) =>{
        return `${cca.link}${cca.apiKey}&channelId=${cca.channelId}${cca.extLink}${maxResults}`;
    }
    
    useEffect(()=>{
        axios.get(builtUrl()).then((r)=>{ 
            embedUrl(r?.data?.items);
        }).catch((error)=>{
            console.log(error);
        });
    }, []);
    return(
        <div className="youtube-feed-iframe-container">
            <h1>Our Videos</h1>
            {
                feeds.length?
                feeds.map((feedUrl, key)=>(
                    <iframe key={key}
                        src={feedUrl} 
                        title={cca.title}
                        frameBorder="0" 
                        allow={cca.allows}
                        allowFullScreen 
                        className="youtube-feed-iframe"
                    ></iframe>
                )):
                <>
                {fallBackFeeds.map((feedId, key)=>(
                    <iframe key={key}
                        src={cca.embededUrl + feedId} 
                        title={cca.title}
                        frameBorder="0" 
                        allow={cca.allows}
                        allowFullScreen 
                        className="youtube-feed-iframe"
                    ></iframe>
                ))}
                </>
            }
        </div>
    )
}