import { createContext, useContext, useState } from "react";



const SwipeProvider = createContext();
export const useStateManagement = () => useContext(SwipeProvider);

export const StateManagement = ({children}) =>{
    const [sharePopup, setSharePopup] = useState(true);

    const providerValue = {
        sharePopup,
        setSharePopup
    }

    return(
        <SwipeProvider.Provider value={providerValue}>
            {children}
        </SwipeProvider.Provider>
    )
}