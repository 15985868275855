import React, { useRef, useEffect, useState } from "react";
import $ from 'jquery';
import { tools } from '../../tools/Tools';
import { InputEntry } from "../../widgets/InputEntry";
import { CCALocation } from "../../components/CCALocation";
import { AnimatedPage } from "../../animations/AnimatedPage";
import { ShareSolideout } from "../../ads/ShareSolideout";
import { ApplyAndEventsSlideout } from "../../ads/ApplyAndEventsSlideout";
import { NavBar } from "../layouts/NavBar";
import { api } from "../../requests/Api";


export const Contact = (optionStatus) => {
    const [successMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const fNameRef = useRef();
    const lNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const messageRef = useRef();
    const contactUsRef = useRef();

    const errorDisplay = (element) => {
        $(element).parent().css({ border: '1px solid red' });
        return false;
    }

    const validate = () => {
        let isValid = true;
        if (!fNameRef.current.value) {
            isValid = errorDisplay(fNameRef.current);
        }
        if (!lNameRef.current.value) {
            isValid = errorDisplay(lNameRef.current);
        }
        if (!phoneRef.current.value) {
            isValid = errorDisplay(phoneRef.current);
        }
        if (!tools.isValid.email(emailRef.current.value)) {
            isValid = errorDisplay(emailRef.current);
        }
        if (!messageRef.current.value) {
            isValid = errorDisplay(messageRef.current);
        }
        return isValid;
    }

    const sendEmail = (e) => {
        e.preventDefault();
        if (!validate()) return;
        const data = {
            firstName: fNameRef.current.value,
            lastName: lNameRef.current.value,
            phone: phoneRef.current.value,
            email: emailRef.current.value,
            message: messageRef.current.value
        }
        api.user.createProspect(data).then((response)=>{
            setSuccessMessage('Message sent.');
        }).catch((error)=>{
            setErrorMessage(tools.error.message(error));
        });
    }

    const formatPhoneNumber = (e) => {
        let formatValue = e.target.value;
        if (!formatValue) return;
        formatValue = formatValue.replace(/[^\d]/g, '');
        formatValue = `${formatValue.slice(0,1)} (${formatValue.slice(1,4)}) ${formatValue.slice(4,7)}-${formatValue.slice(7,11)}`;     
        phoneRef.current.value = formatValue;
    }
    
    useEffect(() => {
        contactUsRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
    }, [contactUsRef, optionStatus]);
      
    return ( 
        <AnimatedPage>
            <ShareSolideout/>
            <ApplyAndEventsSlideout/>
            <NavBar stickToTop />
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <div ref={contactUsRef} className="contact-main-container">
                <div className="contact-form-container">
                    <div className="contact-form-header">
                        <h1>Contact Form</h1>   
                    </div> 
                    <form onSubmit={sendEmail} className="contact-form-detail">
                        <InputEntry inputRef={fNameRef} title="First Name" placeholder={'John'} required/> 
                        <InputEntry inputRef={lNameRef} title="Last Name" placeholder={'Wick'} required/>
                        <InputEntry inputRef={emailRef} title="Email" placeholder={'someone@address.com'} required type={'email'}/>
                        <InputEntry inputRef={phoneRef} title="Phone" onChange={formatPhoneNumber} placeholder = "1 (999) 999-9999"/>
                        <InputEntry title={'Message'} rows = {4} inputRef={messageRef} required/> 
                        <input className="btn shadow-sm" type="submit" value={'GO'} /> 
                    </form > 
                </div>

                <div className="contact-map-container">
                    <div className="contact-map"> 
                        <CCALocation/>
                    </div> 
                </div> 
            </div>
        </AnimatedPage>
    )
}