import React from 'react';
import defaultImg from '../images/profile-default.png';



export const TeamCardList = ({teams, title}) =>{
    return(
        <div className="team-members">
            <h1>{title}</h1>
            {teams?.map?.((member, key)=>(
                <div className="team-members-card" key={key}>
                    <img className="team-members-image" src={member.image || defaultImg} alt="" draggable={false} />
                    <div className="team-mumbers-name">{member.name}</div>
                    <div className="team-members-role">{member.role}</div>
                </div>
            ))} 
        </div>
    )
}