import React from "react";
import { developersContent, developersMembers } from "../contents/developers";
import { DevelopersCardList } from "../../components/DevelopersCardList";
import { PublicLayout } from "../layouts/PublicLayout";


export const Developers = () =>{
    return(
        <PublicLayout>
            <div className="developers-container">
                <div className="developers-content">
                    <h1>Caribbean Coding Academy</h1>
                    <h1>Development Team</h1>
                    <div className="developers-seperator"/>
                    <p>{developersContent.aim}</p>
                    <p>{developersContent.objective}</p>
                </div>
                <DevelopersCardList 
                    title="Meet the Developers" 
                    developers={developersMembers} 
                />
            </div>
        </PublicLayout>
    )
}